import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '~/lazy_apollo/client';
import BasicModal from '../../../../admin/shared/BasicModal';
import createAuthorizePaymentPageTokenMutation from '../../../../libs/gql/mutations/menu_item_carts/createAuthorizePaymentPageTokenMutation.gql';
import { usePopmenuConfig } from '../../../../utils/withPopmenuConfig';
import { round } from '../../../../utils/numbers';
import { toQueryStringObject } from '../../../../utils/urls';
import Loading from '../../../../shared/Loading';

const MenuItemCartAuthorizePaymentModal = ({ amount, locationId, onPaymentReady, onTokenized }) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [formToken, setFormToken] = useState(null);
  const [formNode, setFormNode] = useState(null);
  const [iframeHeight, setIframeHeight] = useState(0);
  const [iframeLoading, setIframeLoading] = useState(true);

  const [createAuthorizationPaymentPageToken, { loading: tokenLoading }] = useMutation(createAuthorizePaymentPageTokenMutation);
  const popmenuConfig = usePopmenuConfig();

  // We need to round the amount to match the rounded total in the back end (half to even)
  const roundedAmount = round(amount, 2);

  const onFormRefChange = useCallback((node) => {
    setFormNode(node);
  }, []);

  const closeModal = useCallback(() => {
    setShowPaymentModal(false);
    setIframeHeight(0);
  }, []);

  useEffect(() => {
    onPaymentReady(() => {
      setShowPaymentModal(true);
    });
  }, [onPaymentReady]);

  useEffect(() => {
    if (!formNode) {
      return undefined;
    }

    const abortController = new AbortController();
    createAuthorizationPaymentPageToken(
      {
        context: { fetchOptions: { signal: abortController.signal } },
        variables: {
          input: {
            amount: roundedAmount,
            locationId,
          },
        },
      },
    ).then(({ data }) => {
      setFormToken(data.createAuthorizePaymentPageToken);
      setIframeLoading(true);
      formNode?.submit();
    });

    return () => {
      abortController.abort();
    };
  }, [roundedAmount, locationId, createAuthorizationPaymentPageToken, formNode]);

  useEffect(() => {
    if (!window.AuthorizeNetIFrame) {
      window.AuthorizeNetIFrame = {};
    }
    window.AuthorizeNetIFrame.onReceiveCommunication = (queryString) => {
      console.log('onReceiveCommunication', queryString);
      const params = toQueryStringObject(`?${queryString}`);

      switch (params.action) {
        case 'successfulSave':
          break;
        case 'cancel':
          closeModal();
          break;
        case 'resizeWindow': {
          const h = parseInt(params.height, 10);
          setIframeHeight(h);
          break;
        }
        case 'transactResponse': {
          const response = JSON.parse(params.response);
          if (response.responseCode === '1') {
            onTokenized({
              amount: response.totalAmount,
              authorization: response.authorization,
              cardBrand: response.accountType,
              cardLast4: response.accountNumber,
              transactionId: response.transId,
            });
            closeModal();
          }
          break;
        }
      }
    };
  }, [closeModal, onTokenized]);

  return (
    <BasicModal
      closeButton={false}
      closeModal={closeModal}
      show={showPaymentModal}
      size="md"
    >
      <div id="iframe_holder" className="center-block" width="100%">
        {(tokenLoading || iframeLoading) && (<Loading />)}
        <iframe
          id="add_payment"
          className="embed-responsive-item panel"
          name="add_payment"
          width="100%"
          height={iframeHeight}
          frameBorder="0"
          scrolling="no"
          title="Authorize.Net Payment"
          onLoad={() => {
            setIframeLoading(false);
          }}
        />
        <form ref={onFormRefChange} id="send_token" action={popmenuConfig.authorizePaymentUrl} method="post" target="add_payment">
          <input hidden name="token" value={formToken} />
        </form>
      </div>
    </BasicModal>
  );
};

MenuItemCartAuthorizePaymentModal.propTypes = {
  amount: PropTypes.number.isRequired,
  locationId: PropTypes.func.isRequired,
  onPaymentReady: PropTypes.func.isRequired,
  onTokenized: PropTypes.func.isRequired,
};

export default MenuItemCartAuthorizePaymentModal;
